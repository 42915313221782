<template>
    <div class="container gradient_box" :class="{'right-box': third_right == '1', 'wrong-box': third_right == '2'}">
        <div class="header">
            <img src="../../assets/images/moli/logo.png" alt="Molly Tea Logo" class="logo">
            <h1 class="title">Win a Jasmine Tea Latte</h1>
            <p class="subtitle">Select premium ingredients</p>
        </div>

        <div class="middle">
            <!-- <img src="../images/background.png" alt="" class="middle_bg"> -->
             <div class="cup"></div>
            <div class="main">
                <div class="step-counter">
                    <span class="current">3</span>/<span class="total">3</span>
                    <h2 class="step-title">Select the Extra</h2>
                </div>

                <div class="options">
                    <div class="option right" :class="{selected: third_right == '1'}" @click="doChoose(1)">
                        <div class="option-main">
                            <span class="icon"></span>
                            <img src="../../assets/images/moli/notopping.png" alt="Premium Tea">
                            <div class="option-title">No Topping</div>
                        </div>
                    </div>
                    <div class="option wrong" :class="{selected: third_right == '2'}" @click="doChoose(2)">
                        <div class="option-main">
                            <span class="icon"></span>
                            <img src="../../assets/images/moli/topping.png" alt="Tea Bag">
                            <div class="option-title">Topping</div>
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="note">
                        <span class="right">Great choice! Experience the pure essence of jasmine tea latte with no distractions—just a flawless blend of tea and milk.</span>
                        <span class="wrong">Too much topping can affect the original taste, why not try a More Refreshing taste?</span>
                    </div>
                    <div class="btn-box">
                        <a href="javascript:;" class="btn" @click="goNext">Next</a>
                    </div>
                </div>
                <div class="minipass">Powered by minipass</div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'MoliGame3',
        data() {
            return {
                now_step: window.localStorage.getItem('moli_step') || 1,
                third_right: ''
            };
        },
        beforeUpdate() {},
        updated() {},
        beforeCreate() {},
        created() {
            this.init();
            if (this.now_step < 3) {
                this.$router.replace({
                    path: '/moli/' + this.now_step
                });
            } else if (this.now_step == 5) {
                this.$router.replace({
                    path: '/moli/4'
                });
            }
        },
        watch: {
        },
        computed: {
            ...mapGetters({
                msg: 'app/get_app_msg'
            }),
            ...mapState({
            }),
        },
        methods: {
            ...mapActions({
                getMoliStatus: 'draw/getMoliStatus',
            }),
            ...mapMutations({
            }),
            async init() {
                let res = await this.getMoliStatus();

                if (res && res.code == 100005) {
                    this.$router.replace({
                        path: '/moli/over'
                    });
                }
            },
            doChoose(answer) {
                this.third_right = answer.toString();
            },
            goNext() {
                if (this.third_right == '2' || this.third_right == '') {
                    return;
                }

                window.localStorage.setItem('moli_step', 4);

                this.$router.push({
                    path: '/moli/4'
                })
            }
        }
    };
</script>

<style scoped>
    @import '../../assets/style/moli/base.css';
    @import '../../assets/style/moli/index.css';

    .cup {
        background-image: url(../../assets/images/moli/cup5.png);
    }

    .right-box .cup {
        background-image: url(../../assets/images/moli/cup5.png);
    }

    .wrong-box .cup {
        background-image: url(../../assets/images/moli/cup6.png);
    }
</style>